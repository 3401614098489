import axios from 'axios';
import * as Yup from 'yup';
import {ApiResource} from '../types/ApiResource';
import {Client} from '../types/Client';
import {SuccessResponse} from '../types/SuccessResponse';

export const clientSchema = Yup.object({
  id: Yup.number(),
  name: Yup.string().max(255, 'Name cannot exceed 250 characters.').required(),
  // only allow letters and dashes
  mnemonic: Yup.string()
    .matches(/^[a-zA-Z-]+$/, 'Mnemonic can only contain letters and dashes.')
    .required(),
  logo_url: Yup.string().nullable(),
  favicon_url: Yup.string().nullable(),
  website: Yup.string().url(),
  address1: Yup.string().max(255, 'Street Address cannot exceed 255 characters').required(),
  address2: Yup.string().max(255, 'Street Address cannot exceed 255 characters').nullable(),
  state: Yup.string().required(),
  city: Yup.string().max(255, 'Street Address cannot exceed 255 characters').required(),
  zip: Yup.string()
    .min(5, 'Zip code must be 5 digits long')
    .max(5, 'Zip code must be 5 digits long')
    .required(),
  timezone: Yup.string().required('Timezone is required'),
  call_time: Yup.string()
    .min(3, 'HHMM format is required (e.g., 2030)')
    .max(4, 'HHMM format is required (e.g., 2030)'),
  sms_time: Yup.string()
    .min(3, 'HHMM format is required (e.g., 2030)')
    .max(4, 'HHMM format is required (e.g., 2030)'),
  sms_same_day_time: Yup.string()
    .min(3, 'HHMM format is required (e.g., 2030)')
    .max(4, 'HHMM format is required (e.g., 2030)'),
  sms_same_day_cutoff_time: Yup.string()
    .min(3, 'HHMM format is required (e.g., 2030)')
    .max(4, 'HHMM format is required (e.g., 2030)'),
  email_time: Yup.string()
    .min(3, 'HHMM format is required (e.g., 2030)')
    .max(4, 'HHMM format is required (e.g., 2030)'),
  call_days_before: Yup.number().min(1).max(30),
  sms_days_before: Yup.number().min(1).max(30),
  email_days_before: Yup.number().min(1).max(30),
  base_url: Yup.string().url().nullable(),
  should_skip_holidays: Yup.boolean().nullable(),
  should_skip_weekends: Yup.boolean().nullable(),
  is_live: Yup.boolean(),
  primary_color: Yup.string().nullable(),
  secondary_color: Yup.string().nullable(),
  enabled_languages: Yup.array().of(Yup.string()),
});

const client: Omit<ApiResource<Client>, 'get'> & {
  get: (params?: string[] | null | undefined) => Promise<Client>;
} = {
  all: async () => {
    const reply = await axios.get<Client[]>(`/api/admin/client`);
    return reply.data;
  },
  get: async (params: string[] | null = null) => {
    let urlParams = '';
    if (params) {
      params.forEach((param) => {
        urlParams += `&${param}`;
      });
    }
    const reply = await axios.get<Client>(`/api/client?${urlParams}`);
    return reply.data;
  },
  create: async (data) => {
    const reply = await axios.post<Client>(`/api/client`, data);
    return reply.data;
  },
  update: async (data) => {
    const reply = await axios.put<Client>(`/api/client/${data.id}`, data);
    return reply.data;
  },
  delete: async (id: number) => {
    const reply = await axios.delete<SuccessResponse>(`/api/client/${id}`);
    return reply.data;
  },
};

export default client;
